import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getData } from "../../../backend/api";

const StatusContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const StatusMessage = styled.div`
  margin-bottom: 10px;
  font-weight: bold;
`;

const ProgressBar = styled.div`
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
`;

const ProgressFill = styled.div`
  height: 100%;
  background-color: #4caf50;
  width: ${(props) => props.progress}%;
  transition: width 0.3s ease-in-out;
`;

const UploadStatusDisplay = ({ sessionId }) => {
  const [status, setStatus] = useState({ message: "Initializing...", progress: 0 });
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const response = await getData({ sessionId }, "status");
        setStatus(response.data.response);
      } catch (err) {
        setError("Failed to fetch status");
      }
    };

    // Initial fetch
    fetchStatus();

    // Set up interval for periodic updates
    const interval = setInterval(fetchStatus, 2000); // Adjust interval as needed

    return () => clearInterval(interval);
  }, [sessionId]);

  if (error) {
    return <StatusContainer>Error: {error}</StatusContainer>;
  }

  return (
    <StatusContainer>
      <StatusMessage>Status: {status.message}</StatusMessage>
      <ProgressBar>
        <ProgressFill progress={status.progress} />
      </ProgressBar>
      <div>Progress: {status.progress}%</div>
    </StatusContainer>
  );
};

export default UploadStatusDisplay;
