import styled, { css } from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./datepicker.css";
export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  &.input {
    flex-direction: column;
    flex: 1 1 calc(50% - 10px);
    align-items: baseline;
  }
  > label {
    color: #232227;
    white-space: nowrap;
    font-size: 12px;
  }
`;
export const CountryCode = styled.div`
  display: flex;
  position: absolute;
  left: 4px;
  top: 6px;
  border-radius: 0;
  border-right: 1px solid lightgrey;
  padding: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  .options {
    position: absolute;
    top: 33px;
    z-index: 1001;
    background: white;
    border: 1px solid #e9e9e9;
    transition: all 0.2s ease 0s;
    padding: 8px;
    width: max-content;
    text-align: justify;
    gap: 5px;
    display: flex;
    flex-direction: column;
    border-radius: 11px;
    left: 0;
    .option {
    }
    .line {
      border-bottom: 1px solid lightgray;
    }
  }
`;
export const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex: calc(50% - 10px);
  animation: ${(props) => props.animation};
  flex-direction: column;
  &.filter {
    flex-direction: row;
    gap: 10px;
    flex: none;
    svg {
      display: none;
    }
  }
  &.checkbox {
    width: 100%;
    flex: none;
    height: 40px;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    flex-direction: row;
  }
  &.short {
    width: 30px;
    min-width: auto;
  }
  &.disabled {
    display: none;
  }
  &.textarea {
    flex: calc(100% - 10px);
  }
  &.direct input {
    /* background-color:transparent; */
    margin-bottom: 0;
    border-radius: 0;
  }
  &.small {
    width: 25%;
    flex: 1 1 calc(25% - 10px);
  }
  &.full {
    flex: 1 1 calc(100%);
  }
  &.left {
    margin-left: 0;
    margin-right: auto;
  }
  &.right {
    margin: 0;
    margin-left: auto;
  }
  &.center {
    margin: 0;
    margin-left: auto;
    margin-right: auto;
  }
  && {
    /* Styles to apply when parent has class shrink */
    .popup & {
      &:nth-of-type(even) {
        margin-left: 5px;
      }
      &:nth-of-type(odd) {
        margin-right: 5px;
      }
      &.textarea {
        flex: calc(100% - 0px);
        margin-right: 0px;
      }
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  @media (max-width: 768px) {
    flex: 100%;
    margin: 0px !important;
    &.checkbox {
      flex: 1 1 100%;
      margin: 0px 5px 15px !important;
    }
  }
  ${(props) =>
    props.children &&
    css`
      & input:focus ~ .floating-label,
      textarea:focus ~ .floating-label,
      textarea:not(:focus):valid ~ .floating-label,
      input:not(:focus):valid ~ .floating-label,
      input[type="date"]:not(:focus):invalid ~ .floating-label,
      .filter input[type="date"]:not(:focus):invalid ~ .floating-label,
      input[type="datetime-local"]:not(:focus):invalid ~ .floating-label,
      .filter input[type="datetime-local"]:not(:focus):invalid ~ .floating-label {
        top: -1px;
        left: 13px;
        right: 6px;
        font-size: 11px;
        opacity: 1;
        text-align: left;
        height: 19px;
        padding: 3px 0;
      }
    `}
`;
export const FileContainer = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 10px;
  outline: none !important;
  width: 100%;
  border-radius: 10px;
  border: 0px solid Black;
  margin: 0 0px 0px 0;
  font-weight: 700;
  border: 1px solid #e9e9e9;
  -webkit-transition: all 0.2s ease-out 0s;
  transition: all 0.2s ease-out 0s;
  color: ${(props) => props.theme.secForeground};
  background: ${(props) => props.theme.secBackground};
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column-reverse;
  &.disabled {
    display: none;
  }
  button {
    background-color: transparent;
    border: 0;
    display: flex;
    flex-direction: column;
    margin: auto;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg {
      font-size: 20px;
      margin-bottom: 5px;
    }
  }
`;
export const Label = styled.label`
  /* Enter Job Title */
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 232.9%;
  flex: none;
  order: 0;
  flex-grow: 0;
  color: ${(props) => props.theme.lightForeground};
  background-color: ${(props) => props.theme.setBackground};
  text-align: left;
  &.phone2 {
    left: 70px;
  }
  &.phone3 {
    left: 75px;
  }
  &.shrink {
    color: ${(props) => props.theme.theme};
    &.phone2 {
      left: 20px;
    }
    &.phone3 {
      left: 25px;
    }
  }
  &.error {
    color: red;
    color: red;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 93%;
  }
  &.checkbox {
    position: initial;
    pointer-events: initial;
    display: flex;
    align-items: center;
  }
  svg {
    margin-right: 5px;
    color: ${(props) => props.theme.theme};
  }
  && {
    /* Styles to apply when parent has class shrink */
    .filter & {
      top: 10px;
      color: rgb(35, 34, 39);
      white-space: nowrap;
      font-size: 12px;
      position: initial;
      align-items: center;
      display: flex;
    }
  }
`;

export const CheckBox = styled.input`
  margin: 0;
  margin-right: 5px;
  & ~ .checkmark {
    background-color: #ccc;
  }

  &:checked ~ .checkmark {
    background-color: #2196f3;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  &:checked ~ .checkmark:after {
    display: block;
  }

  .container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
export const Input = styled.input`
  box-sizing: border-box;
  padding: 13px 10px;
  outline: none !important;
  width: 100%;
  border-radius: 10px;
  border: 0px solid ${(props) => props.theme.border};
  height: 40px;
  margin: 0 0px 0px 0;
  padding-left: 1em;
  font-weight: 700;
  border: 1px solid #e9e9e9;
  transition: all 0.2s ease-out 0s;
  color: ${(props) => props.theme.secForeground};
  background: ${(props) => props.theme.secBackground};
  &.phone2 {
    padding-left: 65px;
    color: black;
  }
  &.phone3 {
    padding-left: 70px;
    color: black;
  }
  &.shrink {
    color: ${(props) => props.theme.theme};
    &.phone2 {
      padding-left: 65px;
    }
    &.phone3 {
      padding-left: 70px;
    }
  }

  -webkit-appearance: none;
  &:disabled {
    cursor: not-allowed;
    border: 1px solid #e9e9e9;
  }
`;
export const DatetimeInput = styled(DatePicker)`
  box-sizing: border-box;
  outline: none !important;
  width: 100%;
  border-radius: 10px;
  border: 0px solid ${(props) => props.theme.border};
  margin: 0 0px 0px 0;
  height: 40px;
  padding-left: 1em;
  font-weight: 700;
  border: 1px solid #e9e9e9;
  transition: all 0.2s ease-out 0s;
  color: ${(props) => props.theme.secForeground};
  background: ${(props) => props.theme.secBackground};
  &.shrink {
    /* padding: 1.2em 13px 0px; */
    color: ${(props) => props.theme.theme};
  }
  && {
    /* Styles to apply when parent has class shrink */
    .filter & {
      padding: 0em 13px 0px;
      margin: 4px 0px 0px 0;
      background-color: white;
      height: 40px;
      box-shadow: rgb(221, 234, 250) 5px 5px 20px;
      border: 0;
      border-radius: 5px;
      color: rgb(9, 75, 150);
    }
  }
`;

export const DatetimeInputDirectOrder = styled(DatePicker)`
  box-sizing: border-box;
  outline: none !important;
  width: fit-content;
  border-radius: 10px;
  border: 0px solid ${(props) => props.theme.border};
  margin: 0 0px 10px 0;
  height: 40px;
  padding-left: 1em;
  font-weight: 700;
  border: 1px solid #e9e9e9;
  transition: all 0.2s ease-out 0s;
  color: ${(props) => props.theme.secForeground};
  background: ${(props) => props.theme.secBackground};
  &.shrink {
    padding: 1.6em 13px 0;
    color: ${(props) => props.theme.theme};
  }
`;
export const Button = styled.button`
  height: 40px;
  border-radius: 10px;
  background: ${(props) => props.theme.theme};
  color: ${(props) => props.theme.foregroundInvert};
  &.red {
    background: rgb(229, 49, 33) !important;
    color: white !important;
  }
  border: 1px solid #e9e9e9;
  transition: all 0.5s ease;
  margin-top: 10px;
  max-width: 400px;
  padding: 10px 15px;
  width: auto;
  border-color: ${(props) => props.theme.border};
  cursor: pointer;
  border-width: 0;
  justify-content: center;
  min-width: 130px;
  font-weight: 500;
  font-size: 16px;
  &.close {
    background: #dddddd;
    color: #6a6a6a;
    margin-right: 1em;
    min-width: 120px;
  }

  &.dashed {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 30px;
    gap: 10px;
    background: #edf5ff;
    border: 2px dashed #cfe3fd;
    border-radius: 5px;
    color: #165dad;
    font-weight: 600;
  }
  &.border {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 30px;
    gap: 10px;
    background: #edf5ff;
    border: 2px solid #cfe3fd;
    border-radius: 12px;
    color: #165dad;
    font-weight: 600;
    background: transparent;
    color: ${(props) => props.theme.theme};
  }
  &.left {
    margin: 0;
    margin-right: auto;
  }
  &.small {
    min-width: auto;
    width: -webkit-fill-available;
    background-color: #f9e375;
    color: ${(props) => props.theme.secForeground};
    font-weight: bold;
    padding: 10px;
    height: 30px;
    font-size: 12px;
  }
  &.right {
    margin: 0;
    margin-left: auto;
  }
  &.center {
    margin: 0;
    margin-left: auto;
    margin-right: auto;
  }
  &.secondary {
    background: white;
    color: black;
  }
  &.margin-top {
    margin-top: 10px;
  }
  &:disabled {
    background-color: ${(props) => props.theme.disabledBackground};
    color: ${(props) => props.theme.disabledForeground};
    cursor: not-allowed;
  }
  &.custom {
    margin: 0;
    gap: 5px;
    display: flex;
    align-items: center;
    text-wrap: nowrap;
    width: fit-content;
  }
`;
export const TextArea = styled.textarea`
  box-sizing: border-box;
  font-family: inherit;
  outline: none !important;
  width: 100%;
  border-radius: 10px;
  border: 0px solid ${(props) => props.theme.border};
  height: 70px;
  &.medium {
    height: 150px;
  }
  &.large {
    height: 230px;
  }
  margin: 0 0px 0px 0;
  padding-left: 1em;
  font-weight: 700;
  border: 1px solid #e9e9e9;
  transition: all 0.2s ease-out 0s;
  padding: 13px 10px;
  color: ${(props) => props.theme.secForeground};
  background: ${(props) => props.theme.secBackground};
  &.shrink {
    padding: 1.5em 13px 0px;
    color: ${(props) => props.theme.theme};
  }
`;
export const Info = styled.div`
  padding-left: 0px;
  font-size: 16px;
  width: 100%;
  margin: 5px;
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 5px;
  &.disabled {
    display: none;
  }
  &.title {
    font-size: 16px;
    padding: 5px;
    margin-bottom: 10px;
    border-bottom: 1px solid #d9d9d9;
  }
  svg {
    width: 27px;
    margin-top: 2px;
    align-items: baseline;
    display: flex;
    align-self: baseline;
  }
`;
export const SubHead = styled.div`
  margin: 0px 5px;
  padding-top: 10px;
  padding-bottom: 5px;
  font-weight: 600;
  width: 100%;
  font-size: 17px;
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  align-self: flex-start;
  flex-direction: column;
  > span {
    gap: 10px;
    display: flex;
  }
  &::after {
    content: "";
    height: 1px;
    font-size: 20px;
    background: ${(props) => props.theme.theme};
    margin-top: 5px;
    opacity: 0.6;
  }
  &.custom {
    margin: 0px 0px;
  }
`;
export const CloseButton = styled.span`
  width: 10px;
  height: 10px;
  position: absolute;
  right: 15px;
  top: 12px;
  left: auto;
  z-index: 1;
  color: #868686;
  cursor: pointer;
  &.info-select {
    right: 45px;
  }
`;
export const InfoBox = styled.div`
  position: absolute;
  right: 0px;
  top: 40px;
  left: auto;
  z-index: 10;
  cursor: pointer;
  background: white;
  padding: 10px;
  border-radius: 5px;
  font-size: 12px;
  max-width: 100%;
  border: 1px solid #e9e9e9;
  text-align: left;
  &::before {
    content: "";
    position: absolute;
    top: -10px;
    right: 12px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent white transparent;
  }
  bold {
    font-weight: bold;
  }
  i {
    font-style: italic;
  }
`;
