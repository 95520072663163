import { useEffect } from "react";
import { MultiTabs } from "../../../core/elements";
import { projectSettings } from "../../brand/project";
import { Container } from "../../../core/layout/styels";
import { RowContainer } from "../../../styles/containers/styles";
import { useSelector } from "react-redux";
import { JobAdd } from "./JobAd";
import { TextAdd } from "./textAd";
import Layout from "../../../core/layout";
const ApprovalTool = (props) => {
  const themeColors = useSelector((state) => state.themeColors);
  useEffect(() => {
    document.title = `Approval Tool - ${projectSettings.title}`;
  }, []);
  return (
    <Container className="noshadow">
      <RowContainer theme={themeColors} className={"page-layout"}>
        <MultiTabs
          tabs={[
            { name: "0", title: "jobAd", element: <JobAdd {...props} setLoaderBox={props.setLoaderBox} /> },
            { name: "1", title: "socialMediaDesigns" },
            { name: "2", title: "textCreationDesigns", element: <TextAdd /> },
            { name: "3", title: "quotes" },
          ]}
        ></MultiTabs>
      </RowContainer>
    </Container>
  );
};
export default Layout(ApprovalTool);
