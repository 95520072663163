// its middlewear to handle reducer call to update a state

import { postData } from "../../../backend/api";
import { Encrypt } from "../../../backend/authentication";
import CustomRoutes from "../../../components/project/router/custom";

const fetchLogin = (data) => {
  return (dispatch) => {
    dispatch({
      type: "FETCH_USER_LOGIN_LOADING",
    });
    postData(data, "user/login")
      .then((response) => {
        if (response.status === 200) {
          if (response.data.success) {
            let currentMenu = response.data.menu.find((item) => item.path === "/dashboard");
            const companyId = Array.isArray(response?.data?.user?.companies) && response.data.user.companies.length > 0 ? response.data.user.companies[0].id || "" : "";
            localStorage.setItem("C", Encrypt(companyId));
            currentMenu = currentMenu ? currentMenu : response.data.menu.find((item) => !item.submenu);
            let data = {
              ...response.data,
              menu: [...response.data?.menu, ...CustomRoutes()],
            };
            dispatch({
              type: "MENU_STATUS",
              payload: false,
            });
            dispatch({
              type: "SELECTED_MENU",
              payload: currentMenu ?? { label: "dashboard", icon: "dashboard" },
            });
            dispatch({
              type: "CURRENT_MENU",
              payload: currentMenu.label ?? "dashboard",
            });
            dispatch({
              type: "FETCH_USER_LOGIN_SUCCESS",
              payload: data,
            });
          } else {
            dispatch({
              type: "FETCH_USER_LOGIN_ERROR",
              payload: response.data.error ?? "Something went wrong!",
            });
          }
        } else {
          dispatch({
            type: "FETCH_USER_LOGIN_ERROR",
            payload: response.customMessage ?? "validationFailed",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_USER_LOGIN_ERROR",
          payload: "validationFailed",
        });
      });
  };
};
const clearLogin = () => {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_USER_LOGIN",
    });
  };
};
const clearLoginSession = () => {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_USER_LOGIN_SESSION",
    });
  };
};
const udpateLogin = (data) => {
  return (dispatch) => {
    dispatch({
      type: "FETCH_USER_LOGIN_SUCCESS",
      payload: data,
    });
  };
};

export { fetchLogin, clearLogin, clearLoginSession, udpateLogin };
