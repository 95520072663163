const fetchCompany = (companyId) => {
  return (dispatch) => {
    dispatch({
      type: "FETCH_COMPANY_SUCCESS",
      payload: companyId,
    });
  };
};

export { fetchCompany };
