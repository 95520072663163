import React from "react";
import { FooterBox, FooterContaner, ButtonContanter, FooterMainBox } from "./styels";
import { Button } from "../../elements";
import { help } from "../../../project/brand";

const Footer = () => {
  return (
    <FooterContaner>
      <FooterMainBox>
        <img alt="help" src={help}></img>
        <FooterBox>
          <div>What do you Need?</div>
          <ButtonContanter>
            <Button icon={"support"} type="small" value="Support"></Button>
            <Button icon={"sales"} type="small" value="Sales"></Button>
          </ButtonContanter>
        </FooterBox>
      </FooterMainBox>
    </FooterContaner>
  );
};

export default Footer;
