import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Header, Nav } from "./styels";
import { useDispatch, useSelector } from "react-redux";
import { currentMenu, menuStatus, openedMenu, selectedMenu, selectedSubMenu } from "../../../../store/actions/common";
import { CloseIcon, GetIcon } from "../../../../icons";
import { Logo, MNavClose } from "../header/styels";
import { logo } from "../../../../images";
import { useTranslation } from "react-i18next";
const Menu = (props) => {
  const themeColors = useSelector((state) => state.themeColors);
  const selectedMenuItem = useSelector((state) => state.selectedMenu);
  const [currentMenus] = useState(props.user.menu);
  const { hoverEnabled } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <>
      <Header>
        <Logo src={logo} alt="logo" />
        <MNavClose
          onClick={() => {
            dispatch(menuStatus(false));
          }}
        >
          <CloseIcon />
        </MNavClose>
      </Header>
      <Nav theme={themeColors}>
        {currentMenus?.map((menuItem) => (
          <div className="menu-item" key={menuItem._id}>
            {menuItem.submenus.length > 0 && (menuItem.showInMenu ?? true) ? (
              <Link
                onClick={() => {
                  dispatch(selectedSubMenu(menuItem));
                  dispatch(openedMenu(menuItem._id));
                  if (menuItem.submenus[0]) {
                    dispatch(menuStatus(false));
                    dispatch(openedMenu(menuItem.submenus[0]._id));
                    dispatch(selectedMenu(menuItem.submenus[0]));
                    dispatch(currentMenu(menuItem.submenus[0].label));
                    hoverEnabled(false);
                  }
                }}
                className={`${menuItem._id === selectedMenuItem._id || selectedMenuItem.menu === menuItem._id ? "main active" : "main"}`}
                to={menuItem.submenus[0]?.path}
              >
                <GetIcon icon={menuItem.icon} />
                {props.isMobile ? <span>{t(menuItem.label)}</span> : <span>{t(menuItem.label)}</span>}
              </Link>
            ) : menuItem.showInMenu ?? true ? (
              <Link
                onClick={() => {
                  dispatch(menuStatus(false));
                  dispatch(selectedSubMenu(null));
                  dispatch(selectedMenu(menuItem));
                  dispatch(currentMenu(menuItem.label));
                }}
                className={menuItem._id === selectedMenuItem._id ? "main active" : "main"}
                to={menuItem.path}
              >
                <GetIcon icon={menuItem.icon} />
                {props.isMobile ? <span>{t(menuItem.label)}</span> : <span>{t(menuItem.label)}</span>}
              </Link>
            ) : null}
          </div>
        ))}
      </Nav>
    </>
  );
};

export default Menu;
