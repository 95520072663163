import React from "react";
import styled from "styled-components";
import { GetIcon } from "../../../../icons";
import { getValue } from "../functions";
import { FilterBox } from "../styles";
import { Button } from "../../elements";
import { useTranslation } from "react-i18next";
import LineGraph from "../../dashboard/linegraph";
const ColumnLayout = styled.div`
  display: flex;
  gap: 0px;
  position: absolute;
  background: white;
  left: 0;
  right: 0;
  top: 76px;
  border-radius: 10px;
  box-shadow: rgb(221, 234, 250) 5px 5px 20px;
  flex-direction: column;
`;
const TwoColumnLayout = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  padding: 20px;
  img {
    margin: 0px;
    height: 40px;
  }
`;

const GraphColumn = styled.div`
  flex: 1;
  box-shadow: rgb(221, 234, 250) 5px 5px 20px;
  border-radius: 12px;
`;

const TableColumn = styled.div`
  flex: 1;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  border: none;
  padding: 3px;
  text-align: left;
  color: #23222787;
  font-weight: 400;
  font-size: 14px;
  padding-bottom: 10px;
`;

const Td = styled.td`
  border: none;
  padding: 3px;
  font-weight: 500;
  color: black;
`;

const CombinationContaner = styled.div`
  > .button {
    display: flex;
    gap: 10px;
    align-items: center;
    z-index: 2;
    svg {
      font-size: 10px;
      width: 18px;
    }
    position: relative;
  }
  > .button.open::before {
    content: "";
    position: absolute;
    background: white;
    width: 100%;
    top: 38px;
    height: 50px;
    left: -10%;
    width: calc(120%);
    border-radius: 2px;
  }
  img {
    height: 39px;
    width: 100%;
    object-fit: contain;
    object-position: left;
  }
`;
const CombinationItem = styled.div`
  border-bottom: 1px solid rgb(241 241 241);
`;
const NoDataMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; // Adjust this value as needed
  color: #888;
  font-style: italic;
`;
export const Combination = ({ data, closeModal, setMessage, combinationValue = false, combinations }) => {
  const { t } = useTranslation();
  let imgLink = data.Portalschaltungens?.Logo;
  return (
    <CombinationContaner>
      {combinations?.length > 0 ? (
        <div
          onClick={(event) => {
            if (combinationValue === true) {
              event.stopPropagation();
              closeModal();
            }
          }}
          className={`button ${combinationValue === true ? "open" : ""}`}
        >
          {imgLink?.length > 0 && <img alt="link-icon" src={`${process.env.REACT_APP_CDN}${imgLink}`} />}
          {combinations.length > 1 ? t("combination") : data.Portalschaltungens?.title}
          <GetIcon icon={combinationValue ? "up" : "down"} />
        </div>
      ) : null}

      {combinationValue === true && (
        <ColumnLayout>
          {combinations?.map((item, index) => (
            <CombinationItem key={index}>
              <TwoColumnLayout>
                <img alt="logo" src={`${process.env.REACT_APP_CDN}${item.Logo}`}></img>
                <FilterBox className="right">
                  {data.StellenzeigenStatus === "F8DD7E57-A94A-934B-902E-5C66B1026452" ? (
                    item.Url?.length > 5 && (
                      <Button
                        value="See JobAd online"
                        ClickEvent={(event) => {
                          event.stopPropagation();
                          if (
                            item.Url &&
                            (() => {
                              try {
                                new URL(item.Url);
                                return true;
                              } catch (_) {
                                return false;
                              }
                            })()
                          ) {
                            window.open(item.Url, "_blank");
                          } else {
                            setMessage({
                              type: 1,
                              content: "Invalid or missing URL",
                              proceed: "Okay",
                            });
                            // Optionally, you can show an error message to the user
                            // alert("Invalid or missing URL");
                          }
                        }}
                      />
                    )
                  ) : (
                    <Button isDisabled={true} type="border" value="See online proof" />
                  )}
                </FilterBox>
              </TwoColumnLayout>
              <TwoColumnLayout>
                <GraphColumn>{item.graph && item.graph.length > 0 ? <LineGraph dataItem={item.graph} /> : <NoDataMessage>{t("No data available")}</NoDataMessage>}</GraphColumn>
                <TableColumn>
                  <Table>
                    <thead>
                      <tr>
                        <Th>ONLINE</Th>
                        <Th>OFFLINE</Th>
                        <Th>CLICKS</Th>
                        <Th>PLACE OF USE</Th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <Td style={{ width: "100px" }}>{getValue({ type: "date" }, item.DateOnline)}</Td>
                        <Td style={{ width: "170px" }}>
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                            }}
                          >
                            <span className={`status-${item.isActive.toString()}`}>{t(item.isActive ? "online" : "offline")}</span>
                            {getValue({ type: "date" }, item.DateOffline)}
                          </div>
                        </Td>
                        <Td>{getValue({ type: "text" }, item.Clicks)}</Td>
                        <Td>{getValue({ type: "text" }, item.Place)}</Td>
                      </tr>
                    </tbody>
                  </Table>
                </TableColumn>
              </TwoColumnLayout>
            </CombinationItem>
          ))}
        </ColumnLayout>
      )}
    </CombinationContaner>
  );
};
