import React, { useState } from "react";
import styled from "styled-components";
import { ElementContainer, IconButton } from "../../elements";
import { GetIcon } from "../../../../icons";
import { dateFormat } from "../../functions/date";

const FileContainer = styled.div`
  cursor: pointer;
  width: 200px;
  height: 150px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px 2px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border-radius: 12px;
  img {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0px;
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 12px;
  }
  .left {
    margin-bottom: auto;
    margin-top: 5px;
    padding: 0 10px 0 0;
  }
  .action {
    background-color: #ffffffc2;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    margin-top: 5px;
    padding: 5px;
    width: auto;
  }
  .align.custom {
    margin: 5px 0 auto auto;
  }
`;

const ImagePreview = styled.img`
  position: absolute;
  object-fit: cover;
`;

const OtherPreview = styled.div`
  margin: 10px 0;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  padding: 5px 10px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  background-color: #ffffffc2;
  span {
    display: flex;
    gap: 5px;
  }
`;

const Preview = styled.div`
  margin: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  padding: 0px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 14px;
  padding: 0 10px;
`;

const FileItem = ({ fileSource, alt, action, title = "download", date = new Date() }) => {
  const [downloading, setDownloading] = useState(false);

  const getFileExtension = (filename) => {
    return filename.split(".").pop().toLowerCase();
  };

  const getFileType = (extension) => {
    const fileTypes = {
      pdf: "PDF",
      doc: "Word Document",
      docx: "Word Document",
      xls: "Excel Spreadsheet",
      xlsx: "Excel Spreadsheet",
      png: "Image",
      html: "HTML File",
      jpg: "Image",
      jpeg: "Image",
      gif: "Image",
      txt: "Text File",
      csv: "CSV File",
      zip: "Zip Archive",
      rar: "RAR Archive",
      mp3: "Audio File",
      mp4: "Video File",
      ppt: "PowerPoint Presentation",
      pptx: "PowerPoint Presentation",
    };
    return fileTypes[extension] || "Unknown";
  };

  const handleDownload = () => {
    setDownloading(true);
    try {
      const link = document.createElement("a");
      link.href = fileSource;
      link.target = "new";
      link.download = `${title}.${getFileExtension(fileSource)}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Download error:", error);
      alert("Failed to download the file. Please try again.");
    } finally {
      setDownloading(false);
    }
  };

  const fileExtension = getFileExtension(fileSource);
  const fileType = getFileType(fileExtension);

  const renderPreview = () => {
    if (fileType === "Image") {
      return <ImagePreview src={fileSource} alt={alt} />;
    } else {
      return null;
    }
  };

  return (
    <FileContainer>
      {renderPreview()}
      <Preview>
        <ElementContainer className="left">
          <ElementContainer className="action">{action}</ElementContainer>
          <IconButton ClickEvent={handleDownload} icon={downloading ? "loader" : "download"} disabled={downloading} />
        </ElementContainer>
        {date && <Title>{title}</Title>}
        <OtherPreview>
          <span>
            <GetIcon icon={fileType === "Image" ? "image" : "upload"} />
            {fileType}
          </span>
          <span>{date && dateFormat(date)}</span>
        </OtherPreview>
      </Preview>
    </FileContainer>
  );
};

export default FileItem;
