let companyId = localStorage.getItem("C");
function companyReducer(state = companyId, action) {
  switch (action.type) {
    case "FETCH_COMPANY_SUCCESS":
      return action.payload;
    default:
      return state; 
  }
}
export { companyReducer };
