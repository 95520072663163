export const projectSettings = {
  title: process.env.title ?? "MultiPosT",
  shortTitle: process.env.shortTitle ?? "MultiPosT",
  description: process.env.description ?? "MultiPosT - Personalturm",
  privileges: {
    cleint: "6687c337f3755ca57660ef56",
    manager: "6687ce80f3755ca57660ef5a",
    super: "6687c33ef3755ca57660ef57",
  },
  formInputView: "mixed", // classic, modern, mixed
  theme: {
    theme: "#094B96",
    themeLight: "#f7f7ff",
    pageBackground: "white",
    background: "white",
    foreground: "#4f4f4f",
    border: "#d9d9d9",
    secBackground: "#F6FAFF",
    secForeground: "#094B96",
    lightBackground: "White",
    lightForeground: "Black",
    lightBorder: "Black",
    lightSecBackground: "White",
    lightSecForeground: "black",
    foregroundInvert: "white",
    backgroundInvert: "black;",
    borderThinkness: "0px",
    themeBackground: "#094B96",
    themeForeground: "white",
    secThemeBackground: "#F2A900",
    secThemeForeground: "white",
    disabledBackground: "rgb(200 226 255)",
    disabledForeground: "rgb(149 190 235)",
    gradient: "-webkit-linear-gradient(302deg, rgb(9 75 150), rgb(90, 169, 230) 100%)",
  },
};
