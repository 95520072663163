// import React from "react";
// import { Button } from "../../../../core/elements";

// const ActionButtons = ({ editMode, setEditMode, currentState, handleAction, setShowChat, setShowDetails, t }) => {
//   const handleEditMode = () => {
//     setEditMode(!editMode);
//     if (!editMode) {
//       setShowChat(true);
//       setShowDetails(false);
//     }
//   };

//   const renderActionButtons = () => {
//     return currentState.stage.innerAction?.actions?.map((action) => <Button key={action.label} ClickEvent={() => handleAction(action)} icon={action.icon} value={t(action.label)} />);
//   };

//   if (editMode) {
//     return (
//       <>
//         {renderActionButtons()}
//         <Button style={{ background: "#DDDDDD", color: "#6A6A6A" }} ClickEvent={handleEditMode} value={t("cancel")} />
//       </>
//     );
//   }

//   if (currentState.stage.innerAction?.editable) {
//     return <Button icon={currentState.stage.innerAction.editIcon} style={{ background: "#F9E375", color: "#094B96" }} ClickEvent={handleEditMode} value={t(currentState.stage.innerAction.editLabel)} />;
//   }

//   return renderActionButtons();
// };

// export default ActionButtons;

import React from "react";
import { Button } from "../../../../core/elements";

const ActionButtons = ({ editMode, setEditMode, currentState, handleAction, setShowChat, setShowDetails, t }) => {
  const handleEditMode = () => {
    setEditMode(!editMode);
    if (!editMode) {
      setShowChat(true);
      setShowDetails(false);
    }
  };

  const renderButtons = () => {
    const buttons = [];

    // Render action buttons
    currentState.stage.innerAction?.actions?.forEach((action) => {
      const type = action.showType ?? "edit";
      if ((editMode && type === "edit") || type === "always") {
        buttons.push(<Button key={action.label} ClickEvent={() => handleAction(action)} icon={action.icon} value={t(action.label)} />);
      }
    });

    // Render cancel button in edit mode
    if (editMode) {
      buttons.push(<Button style={{ background: "#DDDDDD", color: "#6A6A6A" }} ClickEvent={handleEditMode} value={t("cancel")} />);
    }

    // Render edit button when not in edit mode and editable is true
    if (!editMode && currentState.stage.innerAction?.editable) {
      buttons.push(<Button icon={currentState.stage.innerAction.editIcon} style={{ background: "#F9E375", color: "#094B96" }} ClickEvent={handleEditMode} value={t(currentState.stage.innerAction.editLabel)} />);
    }

    return buttons;
  };

  return renderButtons();
};

export default ActionButtons;
