import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import { ElementContainer, Select } from "../../elements";
import { Title } from "../styles";
import { getData } from "../../../../backend/api";

const LineGraph = ({
  title = "Visitor Insights",
  dataType = "JSON",
  filterType = "JSON",
  filters = [
    { id: 1, value: "January" },
    { id: 2, value: "February" },
    { id: 3, value: "March" },
  ],
  dataItem = [],
  columns = [
    { name: "click", label: "Clicks", borderColor: "rgb(9, 75, 150)" },
  ],
  label = "id",
  showTitle = false,
}) => {
  const chartRef = useRef(null);
  const [filterItems, setFilterItems] = useState(filters);
  const [mainData, setMainData] = useState(dataItem);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    if (dataType === "API") {
      const fetchData = async (api) => {
        try {
          const response = await getData(filter ? { filter } : {}, api);
          setMainData(response.data);
        } catch (error) {
          console.error("Error fetching data from API:", error);
        }
      };
      fetchData(dataItem);
    }
  }, [dataItem, dataType, filter]);

  useEffect(() => {
    if (filterType === "API") {
      const fetchData = async (api) => {
        try {
          const response = await getData({}, api);
          setFilterItems(response.data);
        } catch (error) {
          console.error("Error fetching filter items from API:", error);
        }
      };
      fetchData(filters);
    }
  }, [filters, filterType]);

  useEffect(() => {
    if (!chartRef.current || !Array.isArray(mainData)) return;

    const data = {
      labels: mainData.map((item) => item[label]),
      datasets: columns.map((column) => {
        const ctx = chartRef.current.getContext("2d");
        const gradient = ctx.createLinearGradient(0, 0, 0, 400);

        // Parse the RGB values
        const rgbMatch = column.borderColor.match(/\d+/g);
        if (rgbMatch && rgbMatch.length === 3) {
          const [r, g, b] = rgbMatch;
          gradient.addColorStop(0, `rgba(${r}, ${g}, ${b}, 0.1)`); // 40% opacity
          gradient.addColorStop(1, `rgba(${r}, ${g}, ${b}, 0)`); // 0% opacity
          gradient.addColorStop(1, `rgba(${r}, ${g}, ${b}, 0)`); // 0% opacity
        } else {
          // Fallback to a default color if parsing fails
          gradient.addColorStop(0, "rgba(185, 174, 52, 0.1)");
          gradient.addColorStop(1, "rgba(185, 174, 52, 0)");
          gradient.addColorStop(1, "rgba(185, 174, 52, 0)");
        }
        return {
          name: column.name,
          label: column.label,
          data: mainData.map((item) => item[column.name]),
          fill: true,
          backgroundColor: gradient,
          borderColor: column.borderColor,
          tension: 0.1,
          cubicInterpolationMode: "monotone",
          pointRadius: 0,
          pointHoverRadius: 8,
          pointHoverBackgroundColor: column.borderColor,
          pointHoverBorderColor: "white",
          pointHoverBorderWidth: 3,
        };
      }),
    };

    const ctx = chartRef.current.getContext("2d");
    if (chartRef.current.chart) {
      chartRef.current.chart.destroy();
    }
    chartRef.current.chart = new Chart(ctx, {
      type: "line",
      data: {
        labels: data.labels,
        datasets: data.datasets,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          mode: "index",
          intersect: false,
        },
        hover: {
          mode: "index",
          intersect: false,
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
            border: {
              display: false,
            },
            ticks: {
              display: true,
              font: {
                family: "Dosis, sans-serif",
              },
            },
            title: {
              display: false,
            },
            drawBorder: true,
          },
          y: {
            grid: {
              display: true,
              color: "rgba(200, 200, 200, 0.2)", // Set your desired color here
              drawBorder: false,
              drawTicks: false,
            },
            border: {
              display: false,
            },
            ticks: {
              display: true,
              font: {
                family: "Dosis, sans-serif",
              },
            },
            title: {
              display: false,
            },
            drawBorder: true,
          },
        },
        plugins: {
          tooltip: {
            mode: "index",
            intersect: false,
          },
          legend: {
            display: false,
            position: "bottom",
            align: "center",
            fullWidth: false,
            reverse: false,
            labels: {
              color: "black",
              font: {
                size: 12,
                style: "normal",
              },
              padding: 20,
              boxWidth: 40,
              usePointStyle: false,
            },
          },
        },
      },
      plugins: [
        {
          id: "hoverLine",
          beforeDraw: (chart) => {
            if (chart.tooltip._active && chart.tooltip._active.length) {
              const activePoint = chart.tooltip._active[0];
              const { ctx } = chart;
              const { x } = activePoint.element;
              const topY = chart.scales.y.top;
              const bottomY = chart.scales.y.bottom;
              const gradientStroke = ctx.createLinearGradient(
                0,
                topY,
                0,
                bottomY
              );
              gradientStroke.addColorStop(0, "rgba(9, 75, 150, 0)"); // Transparent at top
              gradientStroke.addColorStop(0.5, "rgb(9, 75, 150)"); // Solid at bottom
              gradientStroke.addColorStop(1, "rgba(9, 75, 150, 0)"); // Transparent at top
              ctx.save();
              ctx.beginPath();
              ctx.moveTo(x, topY);
              ctx.lineTo(x, bottomY);
              ctx.lineWidth = 2;
              ctx.strokeStyle = gradientStroke;
              ctx.stroke();
              ctx.restore();
            }
          },
        },
      ],
    });
  }, [mainData, columns, label]);

  return (
    <ElementContainer
      className="dashitem column"
      style={{ height: "200px", width: "100%" }}
    >
      {showTitle && (
        <Title>
          <span>{title}</span>
          <Select
            label="Month"
            align="right small"
            value={filter}
            selectApi={filterItems}
            onSelect={(item) => {
              setFilter(item.id ?? null);
            }}
          ></Select>
        </Title>
      )}
      <canvas ref={chartRef} />
    </ElementContainer>
  );
};

export default LineGraph;
