// we are storing the token in the local storage its using only when we use auth token instead of bearer token
import CryptoJS from "crypto-js";
const GetAccessToken = () => {
  try {
    let accessToken = "";
    if (localStorage.getItem("user")) {
      const obj = JSON.parse(localStorage.getItem("user"));
      accessToken = `${obj?.token} ${obj?.refreshToken} ${obj?.userId}`;
    }
    return accessToken;
  } catch (error) {
    return "";
  }
};
const SetAccessToken = (token) => {
  try {
    if (localStorage.getItem("user")) {
      console.log("Token Updated");
      const obj = JSON.parse(localStorage.getItem("user"));
      obj.token = token;
      localStorage.setItem("user", JSON.stringify(obj));
    }
  } catch (error) {
    return "";
  }
};
const SECRET_KEY = process.env.REACT_APP_ENC_KEY; // Replace with a strong secret key

const Encrypt = (id) => {
  const ciphertext = CryptoJS.AES.encrypt(id, SECRET_KEY).toString();
  return encodeURIComponent(ciphertext); // URL-safe
};

const Decrypt = (encryptedId) => {
  const decodedCiphertext = decodeURIComponent(encryptedId);
  const bytes = CryptoJS.AES.decrypt(decodedCiphertext, SECRET_KEY);
  return bytes.toString(CryptoJS.enc.Utf8);
};

export { GetAccessToken, SetAccessToken, Encrypt, Decrypt };
