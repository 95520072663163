import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { GetIcon } from "../../../../icons";
import { dateFormat } from "../../functions/date";
import { useTranslation } from "react-i18next";
import { addPageObject } from "../../../../store/actions/pages";
import Loader from "../../loader";
import { temp } from "../../../project/brand";
import { NoData } from "../styles";

const StagesContainer = styled.div`
  width: 100%;
  overflow-x: auto;
`;

const StagesTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-collapse: collapse;
  gap: 10px;
`;

const TableRow = styled.div`
  display: flex;
  background-color: white;
  justify-content: space-between;
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.74);
  box-shadow: 5px 5px 20px #ddeafa;
  padding: 10px;
  align-items: center;
`;

const JobImage = styled.img`
  min-width: 75px;
  height: 75px;
  object-fit: cover;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgb(221, 234, 250) 5px 5px 20px;
`;

const JobName = styled.div`
  font-weight: bold;
  width: 100px;
  margin-left: 20px;
  margin-right: auto;
  display: flex;
  width: 300px;
  overflow-wrap: anywhere;
`;

const DateStatus = styled.div`
  font-weight: bold;
  width: 200px;
  margin-left: 20px;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  div:first-child {
    font-weight: 400;
    border-radius: 0px;
    color: rgba(35, 34, 39, 0.53);
    text-transform: uppercase;
    font-size: 14px;
  }
  div:last-child {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px;
    gap: 10px;
    background: #edf5ff;
    color: #165dad;
    font-size: 14px;
    border-radius: 10px;
  }
  &.date div:last-child {
    background: rgba(242, 169, 0, 0.15);
    color: #f2a900;
  }
`;

const StepperContainer = styled.div`
  display: flex;
  align-items: center;
  width: max-content;
  align-items: baseline;
  padding-top: 20px;
  width: -webkit-fill-available;
  justify-content: center;
`;

const Step = styled.div`
  width: 20%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0px;
  flex-direction: column;
  text-align: center;
  align-items: center;
  position: relative;
  gap: 10px;
  > div:last-child {
    color: black;
    font-size: 14px;
  }
  ::after {
    content: "";
    position: absolute;
    width: calc(50% - 14px);
    height: 10px;
    top: 10px;
    background-color: #094b96;
    right: 0;
    left: auto;
    border-top: 10px solid #e9ebef;
    border-bottom: 10px solid #e9ebef;
  }
  ::before {
    content: "";
    top: 10px;
    position: absolute;
    width: calc(50% - 14px);
    height: 10px;
    background-color: #094b96;
    left: 0;
    right: auto;
    border-top: 10px solid #e9ebef;
    border-bottom: 10px solid #e9ebef;
  }
  &.status-current::after,
  &.status-upcoming::after,
  &.status-upcoming::before {
    background-color: #adc0d7;
  }
  :first-child::before {
    display: none;
  }
  :last-child::after {
    display: none;
  }
`;

const StepStatus = styled.div`
  display: flex;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  background: #e9ebef;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  div {
    display: flex;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    background: ${(props) => props.theme.theme};
    z-index: 1;
    text-align: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  &.status-current div {
    background-color: #f9e375;
    color: #094b96;
  }
  &.status-upcoming div {
    background-color: #adc0d7;
    color: white;
  }
`;

const ActionButton = styled.div`
  button {
    padding: 12px 16px;
    border-radius: 4px;
    background: ${(props) => props.theme.background};
    color: ${(props) => props.theme.foreground};
    border: none;
    cursor: pointer;
    opacity: ${(props) => (props.disabled ? 0.5 : 1)};
    pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
    white-space: nowrap;
    width: 100%;
  }
  display: flex;
  min-width: 125px;
`;

const getCurrentStage = (listing, progressSequence, stepList) => {
  let currentStageIndex;
  if (progressSequence.includes(".")) {
    const progressParts = progressSequence.split(".");
    currentStageIndex = listing;
    for (let part of progressParts) {
      if (currentStageIndex && typeof currentStageIndex === "object") {
        currentStageIndex = currentStageIndex[part];
      } else {
        currentStageIndex = undefined;
        break;
      }
    }
  } else {
    currentStageIndex = listing[progressSequence];
  }

  let currentStage = null;
  let currentStepIndex = 0;
  for (let step of stepList) {
    if (step.multiple) {
      const matchingStep = step.steps.find((item) => item.sort === currentStageIndex);
      if (matchingStep) {
        currentStage = { ...matchingStep, stepIndex: currentStepIndex };
        break;
      }
    } else if (step.sort === currentStageIndex) {
      currentStage = { ...step, stepIndex: currentStepIndex };
      break;
    }
    currentStepIndex++;
  }

  return { currentStageIndex, currentStage };
};
export const Stages = ({ ListingApi, title = "Titel", progressSequence = "StellenanzeigenPhase.SortID", stepList, onActionClick, dateField = null }) => {
  const theme = useSelector((state) => state.themeColors);
  const { t } = useTranslation();
  const PageData = useSelector((state) => state.pages);
  const company = useSelector((state) => state.company);
  const previousCompanyRef = useRef();
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const setLoaderBox = (status) => {
    setShowLoader(status);
  };

  useEffect(() => {
    if ((previousCompanyRef.current !== undefined && company !== previousCompanyRef.current) || !initialized) {
      dispatch(addPageObject(ListingApi, 0, {}, 0));
    }
    previousCompanyRef.current = company;
  }, [company, ListingApi, dispatch, initialized]);

  const [listings, setListings] = useState({
    data: null,
    isLoading: true,
    error: null,
  });

  useEffect(() => {
    const alldata = PageData[`${ListingApi}`] ?? {
      data: null,
      isLoading: true,
      error: null,
    };
    setLoaderBox(alldata.isLoading);
    if (!alldata.isLoading && alldata?.data) {
      setListings(alldata.data.response);
      setInitialized(true);
    }
  }, [PageData, ListingApi]);

  return (
    <StagesContainer theme={theme}>
      <StagesTable>
        {listings?.length > 0 ? (
          listings?.map((listing) => {
            const { currentStageIndex, currentStage } = getCurrentStage(listing, progressSequence, stepList);
            const isCompleted = (index) => index < currentStageIndex;
            const isCurrent = (index) => index === currentStageIndex;

            return (
              <TableRow key={listing.ID} theme={theme}>
                <JobImage src={temp} alt={listing[title]} />
                <JobName theme={theme}>{listing[title]}</JobName>
                {dateField?.field && (
                  <DateStatus className={t(listing[dateField.field] ? "date" : "assoonas")} theme={theme}>
                    <div>{dateField.title}</div>
                    <div>{listing[dateField.field] ? dateFormat(listing[dateField.field]) : t("assoonas")}</div>
                  </DateStatus>
                )}
                <StepperContainer>
                  {stepList.map((step, index) => {
                    if (step.multiple) {
                      const currentMultipleStep = step.steps.find((s) => s.sort === currentStageIndex);
                      const stepToShow = currentMultipleStep || step.steps[0];
                      return (
                        <Step className={`status-${isCompleted(stepToShow.sort) ? "completed" : isCurrent(stepToShow.sort) ? "current" : "upcoming"}`} key={index} active={stepToShow.sort <= currentStageIndex} theme={theme}>
                          <StepStatus className={`status-${isCompleted(stepToShow.sort) ? "completed" : isCurrent(stepToShow.sort) ? "current" : "upcoming"}`} theme={theme}>
                            <div>
                              <GetIcon icon={stepToShow.icon} />
                            </div>
                          </StepStatus>
                          <div>{t(stepToShow.title)}</div>
                        </Step>
                      );
                    } else {
                      return (
                        <Step className={`status-${isCompleted(step.sort) ? "completed" : isCurrent(step.sort) ? "current" : "upcoming"}`} key={index} active={step.sort <= currentStageIndex} theme={theme}>
                          <StepStatus className={`status-${isCompleted(step.sort) ? "completed" : isCurrent(step.sort) ? "current" : "upcoming"}`} theme={theme}>
                            <div>
                              <GetIcon icon={step.icon} />
                            </div>
                          </StepStatus>
                          <div>{t(step.title)}</div>
                        </Step>
                      );
                    }
                  })}
                </StepperContainer>
                {/* {JSON.stringify(currentStage)} */}
                <ActionButton theme={currentStage?.action}>{currentStage?.action && <button onClick={() => onActionClick(listing, currentStage)}>{t(currentStage?.action.label)}</button>}</ActionButton>
              </TableRow>
            );
          })
        ) : (
          <NoData>{"No tasks found!"}</NoData>
        )}
      </StagesTable>
      {showLoader && <Loader className={"absolute"}></Loader>}
    </StagesContainer>
  );
};
