import React, { useState } from "react";
import { Container, Left, Right } from "./styels";

import { IconButton, Select } from "../../elements";
import { clearLogin } from "../../../../store/actions/login";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Decrypt, Encrypt } from "../../../../backend/authentication";
import { fetchCompany } from "../../../../store/actions/company";
import LanguageTooltip from "../../tooltip";

const Header = ({ user }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const companyId = localStorage.getItem("C");
  const [company, setCompany] = useState(Decrypt(companyId ?? ""));
  return (
    <Container>
      <Left>
        {user.user.companies?.length > 0 && (
          <Select
            addNew="+ Add Another Company"
            selectApi={user.user.companies}
            value={company}
            align="filter header"
            defaultValue={company}
            onSelect={(value) => {
              setCompany(value.id);
              localStorage.setItem("C", Encrypt(value.id) ?? "");
              localStorage.setItem("CO", Decrypt(localStorage.getItem("C")));
              dispatch(fetchCompany(Encrypt(value.id)));
            }}
            showLabel={false}
          ></Select>
        )}
        {/* <Button align="dashed" value={"+ Add Another Company"}></Button> */}
      </Left>
      <Right>
        {/* <Search onChange={(event) => setSearch(event.target.value)} placeholder={"Search.."} active={true} value={search}></Search> */}
        <IconButton icon="notification"></IconButton>
        <IconButton icon="settings"></IconButton>
        <LanguageTooltip></LanguageTooltip>
        <IconButton
          icon="logout"
          ClickEvent={() => {
            dispatch(clearLogin());
            navigate("/");
          }}
        ></IconButton>
      </Right>
    </Container>
  );
};

export default Header;
