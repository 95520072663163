import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import AutoForm from "../../../core/autoform/AutoForm";
import { postData } from "../../../../backend/api";
import Loader from "../../../core/loader";
import { SuccessMessageContainer } from "../styles";
import { useSelector } from "react-redux";

const ForgotPassword = ({ setMessage, handleContinue }) => {
  const themeColors = useSelector((state) => state.themeColors);
  const { t } = useTranslation();
  const [formType, setFormType] = useState("email");
  const [email, setEmail] = useState(null);
  const [resetComplete, setResetComplete] = useState(false);
  const [loader, setLoader] = useState(false);

  const formConfigs = useMemo(
    () => ({
      email: [
        {
          type: "text",
          placeholder: "email",
          name: "email",
          validation: "email",
          default: "",
          add: true,
          label: "email",
          minimum: 5,
          maximum: 40,
          required: true,
        },
      ],
      otp: [
        {
          type: "text",
          placeholder: "Enter OTP",
          name: "otp",
          validation: "otp",
          default: "",
          add: true,
          label: "OTP",
          minimum: 6,
          maximum: 6,
          required: true,
        },
        {
          type: "password",
          placeholder: "New Password",
          name: "newPassword",
          validation: "password",
          default: "",
          add: true,
          label: "New Password",
          minimum: 8,
          maximum: 40,
          required: true,
        },
        {
          type: "info",
          content: t("otpSentMessage", { email: email }),
          name: "info",
          add: true,
        },
      ],
    }),
    [email, t]
  );

  const submitChange = useCallback(
    async (post) => {
      setLoader(true);
      const endpoints = {
        email: "user/forgot-password",
        otp: "user/reset-password",
      };
      try {
        const response = await postData(
          {
            ...(formType === "email" ? { email: post.email } : { email: email }),
            ...post,
          },
          endpoints[formType]
        );
        if (response.status === 200) {
          setFormType((prevType) => {
            switch (prevType) {
              case "email":
                setEmail(post.email);
                setLoader(false);
                setMessage({
                  type: 1,
                  content: "OTP sent to your email",
                  proceed: "Okay",
                  icon: "success",
                });
                return "otp";
              case "otp":
                setMessage({
                  type: 1,
                  content: "Your password has been reset successfully!",
                  proceed: "Okay",
                  icon: "success",
                });
                setLoader(false);
                setResetComplete(true);
                return "otp";
              default:
                setLoader(false);
                return prevType;
            }
          });
        } else {
          setLoader(false);
          setMessage({
            type: 1,
            content: t(response.customMessage),
            proceed: "Okay",
            icon: "error",
          });
        }
      } catch (error) {
        setLoader(false);
        setMessage({
          type: 1,
          content: t(error),
          proceed: "Okay",
          icon: "error",
        });
        console.error("Error during form submission:", error);
      }
    },
    [formType, email, setMessage, t]
  );

  const formInput = formConfigs[formType];
  const getButtonText = () => {
    switch (formType) {
      case "email":
        return t("Send OTP");
      case "otp":
        return t("Reset Password");
      default:
        return t("Submit");
    }
  };

  return (
    <>
      {resetComplete ? (
        <SuccessMessageContainer theme={themeColors}>
          <div className="success-icon">✓</div>
          <h2>{t("passwordResetSuccessful")}</h2>
          <p>{t("youCanNowLoginWithNewPassword")}</p>
        </SuccessMessageContainer>
      ) : (
        <AutoForm useCaptcha={false} key={`type-${formType}`} useCheckbox={false} customClass="embed" description="" formValues={{}} formMode="double" formType="post" header={t("forgotPasswordHead")} formInput={formInput} submitHandler={submitChange} button={t(getButtonText())} isOpenHandler={() => {}} css="plain embed" isOpen={true} plainForm={true} />
      )}
      {loader && <Loader />}
    </>
  );
};

export default ForgotPassword;
