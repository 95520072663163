import styled from "styled-components";
export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 0px);
  padding-bottom: 0px;
  background-color: #f3f8fb;
  &.center {
    text-align: center;
    justify-content: center;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding-bottom: 10px;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: 40%;
  margin-bottom: 60px;
  margin-top: 60px;
  justify-content: center;
  max-width: 1200px;
  width: 100%;
  margin: auto;
  margin: 40px auto;
  &.login {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 5px 9px 0px;
    padding: 20px;
    border-radius: 10px;
    background-color: white;
    width: 800px;
    justify-content: center;
    gap: 20px;
    align-items: center;
  }
  img {
    max-width: 100%;
  }
  @media screen and (max-width: 1200px) and (min-width: 768px) {
    max-width: 768px;
  }
  @media screen and (max-width: 768px) {
    flex: 1 1 100%;
    width: auto;
    padding: 10px;
    margin: 0px auto;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 100%;
    max-height: 64vh;
    object-fit: cover;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    &.hm {
      display: none;
    }
  }
`;
export const Header = styled.div`
  display: flex;
  padding: 10px;
  box-shadow: rgb(0 0 0 / 16%) 0px 5px 9px 0px;
  background-color: white;
  img {
    height: 30px;
  }
`;
export const Switcher = styled.button`
  padding: 0px;
  background-color: transparent;
  border: none;
  outline: none;
  padding-top: 20px;
  font-size: 20px;
  border-top: 1px solid rgb(224, 224, 227);
  width: 100%;
  cursor: pointer;
  span {
    font-weight: bold;
  }
`;
export const ImageBox = styled.div`
  width: 40%;
  display: flex;
  img {
    margin: auto;
  }
`;
export const LoginBox = styled.div`
  width: 60%;
`;
export const BtnForgotPassword = styled.a`
  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent;
  transition: all 0.1s ease-out 0s;
  display: flex;
  gap: 5x;
  padding: 0;
  margin: 0;
  font-size: 16px;
  justify-content: left;
  span {
    text-align: left;
    width: calc(100% - 30px);
  }
  svg {
    width: 30px;
  }
  &.hover:hover {
    color: ${(props) => props.theme.background};
    font-weight: bold;
  }
`;
export const SuccessMessageContainer = styled.div`
  background: ${(props) => props.theme.background};
  color: ${(props) => props.theme.foreground};
  border-radius: 8px;
  padding: 23px 85px 0px 0;
  max-width: 500px;
  margin: auto;

  .success-header {
    display: flex;
    align-items: center;
  }

  .success-icon {
    font-size: 24px;
    color: #4caf50;
    background-color: lightgray;
    color: ${(props) => props.theme.theme};
    margin-right: 15px;

    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }

  h2 {
    color: ${(props) => props.theme.theme};
    margin: 0;
    font-size: 24px;
  }

  p {
    color: #4a4a4a;
    margin-bottom: 20px;
    line-height: 1.5;
    text-align: left;
  }
`;
