import styled from "styled-components";

export const ViewContainer = styled.div`
  position: relative;
`;
export const ViewPopup = styled.div`
  position: fixed;
  top: 0;
  left: 15em;
  right: 0;
  bottom: 0;
  background-color: #f6faff;
  z-index: 1;
`;
export const Title = styled.div`
  font-family: "Dosis";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #094b96;
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
`;
export const DetailsContainer = styled.div`
  display: flex;
  font-family: "Dosis";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 232.9%;
  color: #444444;
  height: calc(100vh - 100px);
  overflow: auto;
  gap: 20px;
`;

export const StyledDetails = styled.div`
  width: calc(100% - 900px);
  display: flex;
  border-radius: 8px;
  flex-direction: column;
  gap: 20px;
`;
export const DetailItem = styled.div`
  width: auto;
  display: flex;
  border-radius: 12px;
  flex-direction: column;
  &.open {
    > div:first-child {
      width: auto;
      border-bottom-right-radius: 0;
    }
    > div:last-child {
      background-color: white;
      margin-left: 30px;
      border-bottom-right-radius: 12px;
      border-bottom-left-radius: 12px;
      padding: 20px;
    }
  }
`;
export const ToggleButton = styled.div`
  font-size: 16px;
  white-space: nowrap;
  display: flex;
  width: max-content;
  gap: 10px;
  align-items: center;
  padding: 10px;
  padding-left: 20px;
  background-color: white;
  border-radius: 0 10px 10px 0;
  position: sticky;
  top: 0;
  button {
    border-radius: 50%;
  }
  &.open {
    background-color: white;
    width: auto;
  }
`;
export const StyledInformations = styled.div`
  padding: 20px;
`;

export const StyledDataItem = styled.div`
  margin-bottom: 15px;
`;

export const StyledLabel = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;

export const StyledContent = styled.div`
  background: #f6faff;
  border: 1px solid rgba(9, 75, 150, 0.12);
  border-radius: 10px;
  padding: 10px;
  min-height: 50px;
`;

export const StyledChatBox = styled.div`
  padding-top: 20px;
  border-radius: 8px;
  overflow: hidden;
`;

export const StyledChats = styled.div`
  max-height: 400px;
  overflow-y: auto;
`;

export const StyledChatItem = styled.div`
  display: flex;
  margin-bottom: 15px;
  flex-direction: column;
  gap: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 20px;
  gap: 10px;
  border-radius: 0px 10px 10px 10px;
  background: #f8f8fa;

  &.active {
    background-color: #cfe3fd;
    border-radius: 10px;
    > div:first-child {
      /* display: none; */
    }
    > div:last-child {
      background-color: #cfe3fd;
    }
  }
`;

export const StyledRoundNo = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #094b96;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  &.active {
    background-color: #f2a900;
  }
`;

export const StyledChatText = styled.div`
  background-color: #f8f8fa;
  border-radius: 8px;
  width: 100%;
  span {
    margin-right: 5px;
    color: gray;
  }
`;
export const EditChat = styled.input`
  background-color: #f8f8fa;
  border-radius: 8px;
  width: 100%;
  border: 0;
  outline: none;
  padding: 10px;
`;
export const StyledInputBox = styled.div`
  display: flex;
  background-color: #fff;
  gap: 10px;
`;

export const StyledTextBox = styled.div`
  flex: 1;
  margin-right: 10px;
`;

export const StyledInputControls = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
`;

export const StyledIframeContainer = styled.div`
  width: 900px;
  border-radius: 12px;
  padding-left: 20px;
  position: sticky;
  top: 0;
  height: calc(100vh - 120px);
  padding: 20px;
  padding-top: 5px;
  background-color: white;
  gap: 10px;
  display: flex;
  overflow: auto;
  flex-direction: column;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  // For Firefox
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
`;
export const StyledIframe = styled.iframe`
  width: 100%;
  border: none;
  overflow: hidden;
`;
export const StyledIframeControls = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  position: sticky;
  top: -5px;
  z-index: 3002;
  padding: 10px 0px;
  background-color: white;

  &.mark {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 20px;
    gap: 10px;
    border-radius: 8px;
    background-color: #fcecc9;
    justify-content: left;
    order: 0;
    font-family: "Dosis";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #f2a900;
    z-index: 0;
  }
`;
