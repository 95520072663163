import styled from "styled-components";

export const FooterContaner = styled.footer`
  display: flex;
  align-items: flex-end;
  margin-top: auto;
  margin-bottom: 1em;
  padding: 0;
  width: 100%;
`;
export const FooterMainBox = styled.div`
  margin: 1em;
  margin-bottom:0em;
  width: 100%;
  img{
    max-width:150px;
    margin-bottom:-20px;
  }
`;
export const FooterBox = styled.div`
  background: #165DAD;
  box-shadow: rgba(0, 0, 0, 0.16) -1px 0px 4px;
  width: 100%;
  border-radius: 12px;
  padding: 0px;
  display:flex;
  flex-direction:column;
  padding:20px;
  position: relative;
  >div{
    font-size:13px;
  }
`;
export const ButtonContanter = styled.div`
  display: flex;
  justify-content: space-between;
  gap:5px;
  margin-top:1em;
`;
export const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.secForeground};

  a {
    text-decoration: none;
    padding: 0.5em 1em;
    color: ${(props) => props.theme.secForeground};
    text-transform: uppercase;
    height: 30px;
    display: flex;
    justify-content: left;
    align-items: center;
  }
  a:hover {
    background: rgb(2, 0, 36);
    background: linear-gradient(
      102deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(25, 138, 214, 1) 0%,
      rgba(8, 34, 95, 0) 83%
    );
    box-shadow: rgb(0 0 0 / 16%) -1px 0px 4px;
  }
  a svg {
    margin-right: 10px;
  }
`;
