import { Stages } from "../../../../core/list/stages";
export const TextAdd = () => {
  const jobListings = [
    {
      id: 1,
      name: "Software Developer",
      imageUrl: "designanzeige_logo.png",
      currentStage: 1,
    },
    {
      id: 2,
      name: "Junior Developer",
      imageUrl: "designanzeige_logo.png",
      currentStage: 2,
    },
    // ... more job listings
  ];
  const stepList = [
    { name: "Answer Questionnaire", title: "Answer Questionnaire", action: { name: "Request", backgroiund: "#F2A900", label: "Request Change" }, icon: "question" },
    { name: "Text is created", title: "Text is created", action: { name: "Request", backgroiund: "#F2A900", label: "Request Change" }, icon: "created" },
    { name: "View draft", title: "View draft", action: { name: "Request", backgroiund: "#F2A900", label: "Request Change" }, icon: "view-draft" },
    { name: "Complete order", title: "Complete order", action: { name: "Request", backgroiund: "#F2A900", label: "Request Change" }, icon: "completed" },
  ];
  return (
    <Stages
      jobListings={jobListings}
      onStageChanged={(listingId, newStage) => {
        // Handle stage change
      }}
      stepList={stepList}
      onActionClick={(listingId) => {
        // Handle action button click
      }}
    />
  );
};
