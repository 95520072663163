import React, { useState, useRef, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const SelectionOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: crosshair;
  &.disabled {
    cursor: auto;
  }
`;

const Selection = styled.div`
  position: absolute;
  border: 2px dashed #00f;
  &:hover {
    background-color: rgba(0, 0, 255, 0.1);
    cursor: pointer;
  }
  &::after {
    content: attr(data-number);
    position: absolute;
    top: -10px;
    right: -10px;
    width: 20px;
    height: 20px;
    background-color: #00f;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    line-height: 12px;
  }
  &.active {
    background-color: rgba(0, 0, 255, 0.1);
    &::after {
      content: attr(data-value);
      width: auto;
      border-radius: 9px;
      padding: 10px;
    }
  }
`;

export const SelectionTool = ({ style, isCurrent, onSelectionChange, selections: temp, selected, selectionChange, screenSize }) => {
  const [selecting, setSelecting] = useState(false);
  const [selection, setSelection] = useState(null);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [selections, setSelections] = useState([]);
  const overlayRef = useRef(null);
  const { t } = useTranslation();
  const getRelativeCoordinates = useCallback((event) => {
    const rect = overlayRef.current.getBoundingClientRect();
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return {
      x: event.pageX - rect.left - scrollLeft,
      y: event.pageY - rect.top - scrollTop,
    };
  }, []);
  useEffect(() => {
    setSelections(temp);
  }, [temp]);
  const handleMouseDown = useCallback(
    (e) => {
      setSelecting(true);
      const { x, y } = getRelativeCoordinates(e);
      setStartPos({ x, y });
    },
    [getRelativeCoordinates]
  );

  const handleMouseMove = useCallback(
    (e) => {
      if (!selecting) return;

      const { x, y } = getRelativeCoordinates(e);

      const newSelection = {
        left: Math.min(startPos.x, x),
        top: Math.min(startPos.y, y),
        width: Math.abs(x - startPos.x),
        height: Math.abs(y - startPos.y),
      };

      setSelection(newSelection);
    },
    [selecting, startPos, getRelativeCoordinates]
  );

  const handleMouseUp = useCallback(
    async (e) => {
      if (selection && onSelectionChange) {
        onSelectionChange(selection);
      }

      setSelection(null);
      setStartPos({ x: 0, y: 0 });
      setSelecting(false);
    },
    [selection, onSelectionChange]
  );

  useEffect(() => {
    if (overlayRef.current) {
      const overlay = overlayRef.current;
      overlay.addEventListener("mousedown", handleMouseDown);
      overlay.addEventListener("mousemove", handleMouseMove);
      overlay.addEventListener("mouseup", handleMouseUp);

      return () => {
        overlay.removeEventListener("mousedown", handleMouseDown);
        overlay.removeEventListener("mousemove", handleMouseMove);
        overlay.removeEventListener("mouseup", handleMouseUp);
      };
    }
  }, [handleMouseDown, handleMouseMove, handleMouseUp]);

  return (
    <SelectionOverlay className={isCurrent ? "" : "disabled"} style={style} ref={isCurrent ? overlayRef : null}>
      {selection && <Selection style={selection} />}
      {selections.map((selectionItem, index) => selectionItem.ScreenSize === screenSize && selectionItem.top && <Selection className={selected === index ? "active" : ""} key={index} style={selectionItem} data-number={index + 1} data-value={selectionItem.Chat?.length > 0 ? selectionItem.Chat : t("noComments")} onClick={() => selectionChange(index, selectionItem.Chat)} />)}
    </SelectionOverlay>
  );
};
