import React, { useEffect, useRef, useState } from "react";
import { Stages } from "../../../../core/list/stages";
import { Title, ViewContainer, ViewPopup, DetailsContainer, StyledDetails, StyledInformations, StyledDataItem, StyledLabel, StyledContent, StyledChatBox, StyledChats, StyledChatItem, StyledRoundNo, StyledChatText, StyledInputBox, StyledIframeContainer, StyledIframeControls, ToggleButton, DetailItem, StyledIframe, EditChat } from "../styles";
import { Container, Left, Right } from "../../../../core/layout/header/styels";
import { Button, IconButton, IconButtonTransparent, Select, TabButtons, TextBox } from "../../../../core/elements";
import { SelectionTool } from "../selection";
import { NoData } from "../../../../core/list/styles";
import { getData, postData, putData } from "../../../../../backend/api";
import { useTranslation } from "react-i18next";
import { GetIcon } from "../../../../../icons";
import ListTable from "../../../../core/list/list";
import PopupView from "../../../../core/popupview";
import { useSelector } from "react-redux";
import ActionButtons from "./actions";
// import { useIframeResize } from "../../../../core/iframe/hook";
export const JobAdd = (props) => {
  const [actions] = useState([
    {
      element: "button",
      type: "callback",
      callback: (item, data, refreshView) => {
        // Handle request change/correction action
        console.log("Request change/correction", data);
      },
      icon: "download",
      title: "Download Zip",
    },
  ]);
  const [attributes] = useState([
    {
      type: "number",
      placeholder: "Version",
      name: "VersionNo",
      showItem: "VersionNo",
      collection: "",
      validation: "",
      default: "",
      label: "Version",
      required: true,
      view: true,
      add: false,
      update: false,
      tag: true,
    },

    {
      type: "text",
      placeholder: "PreviewURL",
      name: "PreviewURL",
      showItem: "PreviewURL",
      collection: "",
      validation: "",
      default: "",
      label: "PreviewURL",
      required: true,
      view: true,
      add: false,
      update: false,
      tag: true,
    },
    {
      type: "textarea",
      placeholder: "Comment to the client",
      name: "Notes",
      showItem: "comment",
      collection: "",
      validation: "",
      default: "We have made all the changes. Please review the jobad. If we need to adjust anything, dont hesitate to tell us",
      label: "Comment to the client",
      required: true,
      view: false,
      add: true,
      update: true,
      tag: false,
    },
    {
      type: "text",
      placeholder: "uplaodedBy",
      name: "uplaodedBy",
      showItem: "FirstName",
      collection: "LoginUser",
      validation: "",
      default: "",
      label: "uplaodedBy",
      required: false,
      view: true,
      add: false,
      update: false,
      tag: true,
    },
    {
      type: "datetime",
      placeholder: "UploadedOn",
      name: "CreatedOn",
      showItem: "UploadedOn",
      collection: "",
      validation: "",
      default: "",
      label: "UploadedOn",
      required: false,
      view: true,
      add: false,
      update: false,
      tag: true,
    },
    {
      type: "file",
      placeholder: "UploadZip",
      name: "file",
      showItem: "file",
      fileAllowed: ".zip,application/zip,application/x-zip,application/x-zip-compressed",
      collection: "",
      validation: "",
      default: "",
      label: "UploadZip",
      required: true,
      view: false,
      add: true,
      update: true,
      tag: false,
    },
  ]);
  const themeColors = useSelector((state) => state.themeColors);
  const [currentState, setCurrentStage] = useState(null);
  const [showDetails, setShowDetails] = useState({});
  const [chatText, setChatText] = useState("");
  const [editChat, setEditChat] = useState("");
  const [portals, setPortals] = useState([]);
  const [screenSize, setScreenSize] = useState("Desktop");
  const [selectFlow, setSelectFlow] = useState("Liquid");
  const [selectedPreview, setSelectedPreview] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  // const [openForm, setOpenForm] = useState(false);
  // const [formInput, setFormInput] = useState([]);
  // const [formType, setFormType] = useState("");
  const iframeRef = useRef(null);
  const [selections, setSelections] = useState([]);
  const [selected, setSelected] = useState(null);
  const { t } = useTranslation();
  const { isAdmin = false } = props;
  const handleSelectionChange = async (newSelection = {}, Chat = "") => {
    if (newSelection?.height > 30 || newSelection?.width > 30 || Chat?.length > 0) {
      const response = await postData({ ...newSelection, ScreenSize: screenSize, Chat, StellenanzeigenID: currentState.listing.ID, PortalschaltungenID: selectedPreview.id }, "chat");
      if (response.status === 200) {
        setSelections((prev) => {
          console.log("Previous selections:", prev);
          const { LeftSize: left, TopSize: top, HeightSize: height, WidthSize: width, ID, Chat, ScreenSize } = response.data.data;
          const updated = [...prev, { left, top, height, width, ID, Chat, ScreenSize }];
          console.log("Updated selections:", updated);
          return updated;
        });
        setShowDetails(false);
        setSelected(null);
      }
    }
  };

  // const resizeIframe = useIframeResize(iframeRef);

  const stepList = isAdmin
    ? [
        {
          sort: 1,
          multiple: false,
          name: "Create ad",
          title: t("Create ad"),
          icon: "question",
          action: { name: "Upload Ad", background: "#094B96", foreground: "White", label: "Upload Ad" },
          innerAction: {
            editable: true,
            editIcon: "upload",
            editLabel: "Upload Versions",
            actions: [{ next: 2, editIcon: "upload", label: "Send for Double Check", icon: "send" }],
          },
        },
        {
          sort: 2,
          multiple: true,
          steps: [
            {
              sort: 2,
              name: "Proofread ad",
              title: t("Proofread ad"),
              icon: "proof-reading",
              action: { name: "proof-read", background: "#094B96", foreground: "White", label: "Double Check" },
              innerAction: {
                editLabel: "Enable Proof Read",
                editIcon: "proof-reading",
                editable: true,
                actions: [{ label: "Send For Approval", next: 3, editIcon: "upload", icon: "send", showType: "edit" }],
              },
            },
            {
              sort: 2.1,
              name: "Modifying ad",
              title: t("Modifying ad"),
              icon: "question",
              action: { name: "Upload Ad", background: "#094B96", foreground: "White", label: "Upload New Version" },
              innerAction: {
                editable: true,
                editLabel: "Modify Versions",
                editIcon: "edit",
                actions: [{ next: 3, label: "Send New Versions", editIcon: "upload", icon: "send" }],
              },
            },
          ],
        },
        { sort: 3, multiple: false, name: "Wait for approval from client", title: t("Wait for approval from client"), icon: "awaiting" },
        {
          sort: 4,
          multiple: true,
          steps: [
            {
              sort: 4,
              name: "Ad Approved",
              title: t("Ad Approved"),
              action: null,
              icon: "proof-reading",
              innerAction: {
                editable: false,
                actions: [{ next: 5, label: "Move to Booked", icon: "arrow-right" }],
              },
            },
            {
              sort: 5,
              name: "Ad Booked / Commissioned",
              title: t("Ad Booked / Commissioned"),
              icon: "commissioned",
              innerAction: {
                editable: false,
                actions: [{ next: 6, label: "Move to Online Check", icon: "arrow-right" }],
              },
            },
          ],
        },
        {
          sort: 6,
          multiple: true,
          steps: [
            {
              sort: 6,
              name: "Online Check",
              title: t("Online Check"),
              action: null,
              icon: "online-check",
              innerAction: {
                editable: false,
                actions: [{ next: 7, label: "Move to Online", icon: "arrow-right" }],
              },
            },
            {
              sort: 7,
              name: "Online",
              title: t("Online"),
              action: null,
              icon: "online-check",
              innerAction: {
                editable: false,
                actions: [{ next: 8, label: "Move to Offline", icon: "arrow-right" }],
              },
            },
            {
              sort: 8,
              name: "Offline",
              title: t("Offline"),
              action: null,
              icon: "online-check",
              innerAction: {
                editable: false,
                actions: [{ next: 7, label: "Move to Online", icon: "arrow-right" }],
              },
            },
          ],
        },
      ]
    : [
        { sort: 1, multiple: false, name: "Create ad", title: t("Create ad"), action: null, icon: "question" },
        {
          sort: 2,
          multiple: true,
          steps: [
            { sort: 2, name: "Proofread ad", title: t("Proofread ad"), action: null, icon: "proof-reading" },
            { sort: 2.1, name: "Modifying ad", title: t("Modifying ad"), action: null, icon: "proof-reading" },
          ],
        },
        {
          sort: 3,
          multiple: false,
          name: "Wait for approval from client",
          title: t("Wait for approval from client"),
          action: { name: "Review Ad", background: "#094B96", foreground: "White", label: "Review Ad" },
          icon: "awaiting",
          innerAction: {
            editable: true,
            editLabel: "Modify Listing",
            editIcon: "edit",
            actions: [
              { next: 4, label: "Approve Ad", icon: "checked", showType: "always" },
              { next: 2.1, label: "Request Modifications", icon: "edit", showType: "edit" },
            ],
          },
        },
        {
          sort: 4,
          multiple: true,
          steps: [
            { sort: 4, name: "Ad Approved", title: t("Ad Approved"), action: null, icon: "proof-reading" },
            { sort: 5, name: "Ad Booked / Commissioned", title: t("Ad Booked / Commissioned"), icon: "commissioned" },
          ],
        },
        {
          sort: 6,
          multiple: true,
          steps: [
            { sort: 6, name: "Online Check", title: t("Online Check"), action: null, icon: "online-check" },
            { sort: 7, name: "Online", title: t("Online"), action: null, icon: "online-check" },
            { sort: 8, name: "Offline", title: t("Offline"), action: null, icon: "online-check" },
          ],
        },
      ];

  function isUrl(string) {
    try {
      new URL(string);
      return true;
    } catch (error) {
      return false;
    }
  }
  async function updateChat(Chat, index, ID) {
    const rep = await putData({ Chat, ID }, "chat");
    if (rep.status === 200) {
      console.log(Chat);
      setSelections((prev) => {
        const newSelections = [...prev];
        newSelections[index].Chat = Chat;
        return newSelections;
      });
    }
  }
  const { setLoaderBox } = props;
  useEffect(() => {
    if (currentState?.listing?.ID && selectedPreview?.id) {
      setLoaderBox(true);
      getData({ StellenanzeigenID: currentState.listing.ID, PortalschaltungenID: selectedPreview.id }, "chat").then((details) => {
        if (details.status === 200) {
          setSelections(
            details.data.data
              ? details.data.data.map((chatITem) => {
                  chatITem.left = chatITem.LeftSize;
                  chatITem.top = chatITem.TopSize;
                  chatITem.height = chatITem.HeightSize;
                  chatITem.width = chatITem.WidthSize;
                  return chatITem;
                })
              : []
          );
          // setSelectedPreview(null);
          setSelected(null);
          setEditChat("");
        }
        setLoaderBox(false);
      });
    }
  }, [currentState, selectedPreview, setLoaderBox]);

  useEffect(() => {
    if (currentState?.listing?.ID) {
      setLoaderBox(true);
      getData({ StellenanzeigenID: currentState.listing.ID }, "stellenanzeigen/details").then((details) => {
        if (details.status === 200) {
          setPortals(details.data.data);
          setSelectFlow(details.data.data.length > 0 ? details.data.data[0] : null);
          setEditChat("");
        }
        setLoaderBox(false);
      });
    }
  }, [currentState, setLoaderBox]);

  useEffect(() => {
    console.log(selectFlow);
    if (selectFlow?.StellenanzeigenPreviews?.length > 0) {
      setSelectedPreview(selectFlow.StellenanzeigenPreviews[0]);
      console.log(selectFlow.StellenanzeigenPreviews[0]);
    } else {
      setSelectedPreview(null);
    }
  }, [selectFlow]);
  const handleToggle = (keyToToggle) => {
    setShowDetails((prev) => {
      const newState = { ...prev };

      // Toggle the selected key
      newState[keyToToggle] = !prev[keyToToggle];

      // Set all other existing keys to false
      Object.keys(newState).forEach((key) => {
        if (key !== keyToToggle) {
          newState[key] = false;
        }
      });

      return newState;
    });
  };
  const handleAction = async (item) => {
    if (item.next && currentState.listing?.ID) {
      setLoaderBox(true);
      await postData({ ID: currentState.listing?.ID, nextStage: item.next }, "stellenanzeigen/change-phase");
      setLoaderBox(false);
    }
  };
  return (
    <ViewContainer>
      <Stages
        ListingApi={isAdmin ? "dashboard/tasks" : "dashboard/jobads"}
        onActionClick={async (listing, stage) => {
          setCurrentStage({ listing, stage });
          setSelected(null);
          // setSelectFlow(listing.previewTypes[0]);
        }}
        dateField={{ title: t("Release date"), field: "releaseDate" }}
        stepList={stepList}
      />
      {currentState && (
        <ViewPopup>
          <Container>
            <Left>
              <Title>
                <IconButtonTransparent ClickEvent={() => setCurrentStage(null)} icon={"arrow-left"} />
                {currentState.listing?.Titel}
              </Title>
            </Left>
            <Right>
              <ActionButtons editMode={editMode} setEditMode={setEditMode} currentState={currentState} handleAction={handleAction} setShowChat={setShowDetails} setShowDetails={setShowDetails} t={t} />
            </Right>
          </Container>
          <DetailsContainer>
            <StyledDetails>
              <DetailItem className={showDetails["details"] && "open"}>
                <ToggleButton>
                  Show Details
                  <IconButton
                    ClickEvent={() => {
                      handleToggle("details");
                    }}
                    icon={showDetails["details"] ? "arrow-left" : "arrow-right"}
                  ></IconButton>
                </ToggleButton>
                {showDetails["details"] && selectFlow && (
                  <StyledInformations>
                    <StyledDataItem>
                      <StyledLabel> {t("Header Image")}</StyledLabel>
                      <StyledContent>
                        <img src={`${process.env.REACT_APP_CDN}${"imgLink"}`} alt="respo" />
                        <img src={`${process.env.REACT_APP_CDN}${"imgLink"}`} alt="respo" />
                      </StyledContent>
                    </StyledDataItem>
                    <StyledDataItem>
                      <StyledLabel> {t("Title for search overview")}</StyledLabel>
                      <StyledContent>{selectFlow.searchOverview ?? ""}</StyledContent>
                    </StyledDataItem>
                    <StyledDataItem>
                      <StyledLabel> {t("Location")}</StyledLabel>
                      <StyledContent>{selectFlow.Locations ?? ""}</StyledContent>
                    </StyledDataItem>
                    <StyledDataItem>
                      <StyledLabel> {t("Postal Code")}</StyledLabel>
                      <StyledContent>{selectFlow.PostalCode ?? ""}</StyledContent>
                    </StyledDataItem>
                    <StyledDataItem>
                      <StyledLabel> {t("Template")}</StyledLabel>
                      <StyledContent>{selectFlow.PostalCode ?? ""}</StyledContent>
                    </StyledDataItem>
                  </StyledInformations>
                )}
              </DetailItem>

              <DetailItem className={showDetails["chat"] && "open"}>
                <ToggleButton>
                  Chat {!showDetails["chat"] && <StyledRoundNo className="active">{selections.length}</StyledRoundNo>}
                  <IconButton
                    ClickEvent={() => {
                      handleToggle("chat");
                    }}
                    icon={showDetails["chat"] ? "arrow-left" : "arrow-right"}
                  ></IconButton>
                </ToggleButton>
                {showDetails["chat"] && (
                  <StyledChatBox>
                    <StyledChats>
                      {selections.length > 0 ? (
                        selections.map((item, index) => (
                          <StyledChatItem
                            key={index} // Add a key prop for React list rendering
                            onClick={() => {
                              if (editMode) {
                                setSelected(index);
                                setEditChat(item.Chat);
                                setScreenSize(item.ScreenSize);
                              }
                            }}
                            className={selected === index ? "active" : ""}
                          >
                            <StyledRoundNo>{index + 1}</StyledRoundNo>
                            {selected !== index ? (
                              <StyledChatText>
                                {isAdmin && <span>{item.AddedBy.LastName}:</span>}
                                {item.Chat?.length > 0 ? item.Chat : t("noComments")}
                              </StyledChatText>
                            ) : (
                              <React.Fragment>
                                <EditChat
                                  type="text"
                                  placeholder="Edit Chat"
                                  onChange={(e) => {
                                    e.stopPropagation();
                                    if (editMode) {
                                      setEditChat(e.target.value);
                                    }
                                  }}
                                  onKeyUp={async (e) => {
                                    // If Enter key is pressed
                                    if (e.key === "Enter") {
                                      updateChat(e.target.value, index, item.ID);
                                    }
                                  }}
                                  name={"tblEdit_" + index}
                                  value={editChat}
                                />
                                {editChat !== item.Chat && (
                                  <IconButton
                                    icon="send"
                                    ClickEvent={(e) => {
                                      e.stopPropagation();
                                      updateChat(editChat, index, item.ID);
                                    }}
                                  />
                                )}
                              </React.Fragment>
                            )}
                          </StyledChatItem>
                        ))
                      ) : (
                        <NoData> {t("No Comments Found!")}</NoData>
                      )}
                    </StyledChats>

                    <StyledInputBox>
                      <TextBox name="textchat" label="" value={chatText} onChange={(value) => setChatText(value)} placeholder={t("Type something here...")}></TextBox>
                      <IconButton
                        align="secondary"
                        icon="send"
                        ClickEvent={(val) => {
                          if (chatText.length > 0) {
                            handleSelectionChange({}, chatText);
                            setChatText("");
                          }
                        }}
                      ></IconButton>
                    </StyledInputBox>
                  </StyledChatBox>
                )}
              </DetailItem>
              <DetailItem className={showDetails["recent"] && "open"}>
                <ToggleButton>
                  Recent JobAds
                  <IconButton
                    ClickEvent={() => {
                      handleToggle("recent");
                    }}
                    icon={showDetails["recent"] ? "arrow-left" : "arrow-right"}
                  ></IconButton>
                </ToggleButton>
                {showDetails["recent"] && <div></div>}
              </DetailItem>
            </StyledDetails>
            <StyledIframeContainer>
              <StyledIframeControls>
                {portals?.length > 0 && (
                  <TabButtons
                    className={"secondary"}
                    showImage={"LogoName"}
                    tabs={portals}
                    selectedTab={selectFlow.key}
                    selectedChange={(val, tab) => {
                      console.log(tab);
                      setSelectFlow(tab);
                    }}
                  ></TabButtons>
                )}
                {/* {portals?.length > 0 && (
                  <Select
                    label={t("Portal")}
                    // showLabel={false}
                    value={selectFlow.id}
                    defaultValue={selectFlow.id}
                    onSelect={(val) => {
                      setSelected(null);
                      setSelectFlow(val);
                    }}
                    iconImage
                    selectApi={portals}
                  ></Select>
                )} */}
                {selectFlow?.StellenanzeigenPreviews && (
                  <Select
                    label={t("Version")}
                    showLabel={false}
                    align="filter header"
                    value={selectedPreview?.id}
                    defaultValue={selectedPreview?.id}
                    onSelect={(val) => {
                      setSelected(null);
                      setSelectedPreview(val);
                    }}
                    selectApi={selectFlow.StellenanzeigenPreviews}
                  ></Select>
                )}
                {isAdmin && editMode && <Button ClickEvent={() => setIsOpen(true)} value={t("uploadZip", { label: t(selectFlow.AnbieterName) })}></Button>}
                <TabButtons
                  tabs={[
                    { icon: "desktop", title: "", key: "Desktop" },
                    { icon: "tablet", title: "", key: "Tablet" },
                    { icon: "mobileview", title: "", key: "Mobile" },
                  ]}
                  selectedTab={screenSize}
                  selectedChange={(val) => setScreenSize(val)}
                ></TabButtons>
              </StyledIframeControls>

              {editMode && (
                <StyledIframeControls className="mark">
                  <GetIcon icon={"solution"}></GetIcon> {t(isAdmin ? "Uploaded version will be availble here, you can also see the client markings!" : "Mark sections on document you want to be edited and leave a comment to us.")}
                </StyledIframeControls>
              )}

              {selectedPreview && isUrl(selectedPreview.PreviewURL) ? (
                <div style={{ position: "relative" }}>
                  {isUrl(selectedPreview.PreviewURL) ? (
                    <>
                      <StyledIframe
                        ref={iframeRef}
                        src={selectedPreview.PreviewURL}
                        style={{
                          height: "3000px",
                          maxWidth: "1200px",
                          width: screenSize === "Desktop" ? "100%" : screenSize === "Tablet" ? "768px" : "576px",
                        }}
                        frameBorder="0"
                        title="See Ad"
                      />
                      {editMode && (
                        <SelectionTool
                          style={{
                            height: "3000px",
                            maxWidth: "100%",
                            width: screenSize === "Desktop" ? "100%" : screenSize === "Tablet" ? "600px" : "300px",
                          }}
                          selections={selections}
                          screenSize={screenSize}
                          selected={selected}
                          isCurrent={!selectedPreview.Locked}
                          selectionChange={(id, Chat) => {
                            setSelected(id);
                            setEditChat(Chat);
                          }}
                          onSelectionChange={handleSelectionChange}
                        />
                      )}
                    </>
                  ) : (
                    <NoData
                      style={{
                        height: "3000px",
                        maxWidth: "100%",
                        width: screenSize === "Desktop" ? "100%" : screenSize === "Tablet" ? "768px" : "576px",
                      }}
                    >
                      Not valid content
                    </NoData>
                  )}
                </div>
              ) : (
                <NoData
                  style={{
                    height: "3000px",
                    maxWidth: "100%",
                    width: screenSize === "Desktop" ? "100%" : screenSize === "Tablet" ? "768px" : "576px",
                  }}
                >
                  Not valid content
                </NoData>
              )}
            </StyledIframeContainer>
          </DetailsContainer>
        </ViewPopup>
      )}
      {isOpen && selectFlow && (
        <PopupView
          // Popup data is a JSX element which is binding to the Popup Data Area like HOC
          popupData={
            <div style={{ padding: "30px" }}>
              <ListTable
                viewMode="table"
                // Actions to be displayed in the ListTable
                actions={actions}
                // API endpoint for fetching menu data
                api={`stellenanzeigen-previews`}
                // Property name for the title of each menu item
                itemTitle={{ name: "Titel", type: "text", collection: "" }}
                // Short name or label for the menu
                shortName={`Version`}
                // Privilege flag indicating whether the user can add menu items
                // formMode={`single`}
                formMode={`single`}
                editCondition={{ when: "Locked", match: false }}
                //
                {...props}
                addPrivilege={true}
                updatePrivilege={true}
                preFilter={{ ProductName: selectFlow.AnbieterName, PortalschaltungenID: selectFlow.key }}
                attributes={attributes}
                referenceId={currentState.listing.ID}
                parentReference="StellenanzeigenID"
                isPopup={true}
              ></ListTable>
            </div>
          }
          themeColors={themeColors}
          closeModal={() => {
            setIsOpen(false);
            setLoaderBox(true);
            getData({ StellenanzeigenID: currentState.listing.ID }, "stellenanzeigen/details").then((details) => {
              if (details.status === 200) {
                setPortals(details.data.data);
                const index = details.data.data.findIndex((item) => item.key === selectFlow?.key);
                if (index !== -1) {
                  setSelectFlow(details.data.data[index]);
                }
                setEditChat("");
              }
              setLoaderBox(false);
            });
          }}
          itemTitle={{
            name: "title",
            type: "text",
            collection: "",
          }}
          openData={{
            data: {
              _id: "",
              title: "Add New Version",
            },
          }} // Pass selected item data to the popup for setting the time and taking menu id and other required data from the list item
          customClass={"medium"}
        ></PopupView>
      )}
    </ViewContainer>
  );
};
