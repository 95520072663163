import React, { useEffect, useState } from "react";
import Layout from "../../../core/layout";
import { Container } from "../../../core/layout/styels";
import { projectSettings } from "../../brand/project";
import ListTable from "../../../core/list/list";
//src/components/styles/page/index.js
//if you want to write custom style wirte in above file
const MyOrders = (props) => {
  useEffect(() => {
    document.title = `My Orders - ${projectSettings.title}`;
  }, []);
  const [attributes] = useState([
    {
      type: "select",
      placeholder: "status",
      name: "status",
      showItem: "status",
      collection: "",
      validation: "",
      value: "all",
      default: "all",
      label: "status",
      apiType: "JSON",
      showLabel: true,
      selectApi: [
        { id: "all", value: "All" },
        { id: "61232D47-560F-9042-A11F-8C447A59CA70", value: "offline" },
        { id: "F8DD7E57-A94A-934B-902E-5C66B1026452", value: "online" },
      ],
      filter: true,
      filterPlacement: "header",
      filterAlign: "left",
      required: false,
      view: false,
      add: false,
      update: false,
      tag: false,
    },
    // {
    //   type: "select",
    //   placeholder: "SortByDate",
    //   name: "SortByDate",
    //   showItem: "SortByDate",
    //   collection: "",
    //   validation: "",
    //   value: "DESC",
    //   default: "DESC",
    //   label: "SortByDate",
    //   apiType: "JSON",
    //   showLabel: true,
    //   selectApi: [
    //     { id: "DESC", value: "newest" },
    //     { id: "ASC", value: "oldest" },
    //   ],
    //   filter: true,
    //   filterPlacement: "header",
    //   filterAlign: "right",
    //   required: false,
    //   view: false,
    //   add: false,
    //   update: false,
    //   tag: false,
    // },
    {
      type: "select",
      placeholder: "filterByCustomer",
      name: "filterByCustomer",
      showItem: "filterByCustomer",
      collection: "",
      validation: "",
      default: "",
      label: "filterByCustomer",
      apiType: "API",
      selectApi: "dashboard/contacts",
      filter: true,
      showLabel: true,
      filterPlacement: "header",
      filterAlign: "right",
      required: false,
      view: false,
      add: false,
      update: false,
      tag: false,
    },
    {
      type: "select",
      placeholder: "ProductType",
      name: "ProductType",
      showItem: "ProductType",
      collection: "",
      validation: "",
      default: "",
      value: "all",
      label: "ProductType",
      showLabel: true,
      apiType: "JSON",
      selectApi: [
        { id: "all", value: "both" },
        { id: "combination", value: "combination" },
        { id: "single", value: "single" },
      ],
      filter: true,
      filterPlacement: "header",
      filterAlign: "right",
      required: false,
      view: false,
      add: false,
      update: false,
      tag: false,
    },
    {
      type: "text",
      placeholder: "IssueNumber",
      name: "IssueNumber",
      showItem: "IssueNumber",
      collection: "SalesDocument",
      validation: "",
      default: "",
      label: "IssueNumber",
      required: true,
      view: true,
      add: true,
      update: true,
      tag: true,
    },
    {
      type: "text",
      placeholder: "JobTitle",
      name: "Titel",
      showItem: "Titel",
      validation: "",
      default: "",
      label: "JobTitle",
      required: true,
      view: true,
      add: true,
      update: true,
      tag: true,
    },
    {
      type: "text",
      placeholder: "JobID",
      name: "JobID",
      showItem: "JobID",
      collection: "",
      validation: "",
      default: "",
      label: "JobID",
      required: true,
      view: true,
      add: true,
      update: true,
      tag: true,
    },
    {
      type: "text",
      placeholder: "Place",
      name: "Place",
      showItem: "Place",
      collection: "",
      validation: "",
      default: "",
      label: "Place",
      required: true,
      view: true,
      add: true,
      update: true,
      tag: true,
    },
    {
      type: "combination",
      placeholder: "jobPortal",
      name: "JobPortal",
      showItem: "title",
      collection: "Portalschaltungens",
      combination: true,
      validation: "",
      default: "",
      label: "JobPortal",
      required: true,
      view: true,
      add: true,
      update: true,
      tag: true,
    },
    {
      type: "date",
      placeholder: "online",
      name: "StartDate",
      showItem: "StartDate",
      collection: "",
      validation: "",
      default: "",
      label: "online",
      required: true,
      view: true,
      add: true,
      update: true,
      tag: true,
      sort: true,
      defaultSort: "desc",
    },
    {
      type: "date",
      placeholder: "offline",
      name: "EndDate",
      showItem: "EndDate",
      collection: "",
      validation: "",
      showStatus: "isActive",
      default: "",
      label: "offline",
      required: true,
      view: true,
      add: true,
      update: true,
      tag: true,
      sort: true,
      defaultSort: "desc",
    },
    {
      type: "text",
      placeholder: "Clicks",
      name: "Clicks",
      validation: "",
      default: "",
      collection: "Portalschaltungens",
      showItem: "Clicks",
      label: "Clicks",
      required: true,
      view: true,
      add: true,
      update: true,
      tag: true,
    },
    {
      type: "text",
      placeholder: "CustomerName",
      name: "CustomerName",
      showItem: "fullName",
      collection: "Contact",
      validation: "",
      default: "",
      label: "CustomerName",
      required: true,
      view: true,
      add: true,
      update: true,
      tag: true,
    },
  ]);
  const [actions] = useState([
    {
      element: "button",
      type: "callback",
      callback: (item, data, refreshView) => {
        // Handle request change/correction action
        console.log("Request change/correction", data);
      },
      icon: "change",
      title: "Request change/correction",
    },
    {
      element: "button",
      type: "callback",
      callback: (item, data, refreshView) => {
        // Handle view invoice action
        console.log("View invoice", data);
      },
      icon: "invoice",
      title: "View invoice",
    },
    {
      element: "button",
      type: "callback",
      callback: (item, data, refreshView) => {
        // Handle preview link action
        console.log("Preview link", data);
      },
      icon: "link",
      title: "Preview link",
    },
    {
      element: "button",
      type: "callback",
      callback: (item, data, refreshView) => {
        // Handle download as PDF action
        console.log("Download as PDF", data);
      },
      icon: "pdf",
      title: "Download as PDF",
    },
    {
      element: "button",
      type: "callback",
      callback: (item, data, refreshView) => {
        // Handle take offline action
        console.log("Take offline", data);
      },
      icon: "offline",
      title: "Take Offline",
      color: "red",
    },
  ]);
  return (
    <Container className="noshadow">
      {/* <RowContainer className={"data-layout"}>
        {data && data.map((item, index) => <div key={index}>{item.Titel}</div>)}
      </RowContainer> */}
      {/* Render a ListTable component */}
      <ListTable
        viewMode="table"
        datefilter={true}
        // Actions to be displayed in the ListTable
        actions={actions}
        // API endpoint for fetching menu data
        api={`dashboard/booking`}
        // Property name for the title of each menu item
        itemTitle={{ name: "Titel", type: "text", collection: "" }}
        // Short name or label for the menu
        shortName={`My Orders`}
        // Privilege flag indicating whether the user can add menu items
        // formMode={`single`}
        formMode={`double`}
        //
        {...props}
        // Additional attributes related to the menu
        attributes={attributes}
      ></ListTable>
    </Container>
  );
};
// exporting the page with parent container layout..
export default Layout(MyOrders);
