import React from "react";
import { HeaderContainer } from "./styles";
import { ColumnContainer } from "../../styles/containers/styles";
import { logo } from "../../../images";
const Header = () => {
  return (
    <HeaderContainer>
      <ColumnContainer className="container header">
        <img src={logo} alt="logo" />
      </ColumnContainer>
    </HeaderContainer>
  );
};
export default Header;
